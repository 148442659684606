import axios from "axios";
import { BACKEND_SERVER_URL, REACT_APP_PROJECT_MODE } from "constants/api.constant";
import { PERSIST_STORE_NAME } from "constants/app.constant";
import deepParseJson from "utils/deepParseJson";

const api = axios.create({
  baseURL: `${BACKEND_SERVER_URL}`,
});

// Add a request interceptor
api.interceptors.request.use(
  async function (config) {
    if (config.url === "/api/auth/admin/login") {
      config.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
    } else {
      const user = localStorage.getItem(PERSIST_STORE_NAME);
      const persistData = deepParseJson(user);
      const token = persistData?.auth?.user?.token;

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  async function (response) {
    // Do something with response data
    const result = response.data;
    return result;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default api;
