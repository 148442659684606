import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  categoryPagination: {},
};

export const configurationSlice = createSlice({
  name: "configurations",
  initialState,
  reducers: {
    setCategoryPagination: (state, action) => {
      state.categoryPagination = action.payload;
    },
  },
});

export const { setCategoryPagination } = configurationSlice.actions;

export default configurationSlice.reducer;
