import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import {
  onSignInSuccess,
  onSignOutSuccess,
  setPermissions,
} from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { useLocation, useNavigate } from "react-router-dom";
import { login } from "service/authService";
import useQuery from "./useQuery";
import { REDIRECT_URL_KEY } from "constants/app.constant";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const query = useQuery();
  const { token, signedIn } = useSelector((state) => state.auth.session);
  // const entryPath = localStorage.getItem("entryPath");
  const signIn = async (data) => {
    try {
      const resp = await login(data);
      if (resp?.success) {
        const token = resp?.token;

        dispatch(onSignInSuccess(token));

        dispatch(
          setUser({
            id: resp?.data.uniqueId ? resp?.data.uniqueId : "",
            username: resp?.data.username ? resp?.data.username : "user",
            email: resp?.data.email ? resp?.data.email : "",
            role: resp?.data?.role ? resp?.data?.role : "",
            authority: resp?.data?.role
              ? resp?.data.role.split(" ")
              : [""],
            token: resp?.token ? resp?.token : "",
          })
        );
        window.location.reload();
        dispatch(onSignInSuccess(token));
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
          data: resp.data,
        };
      } else {
        return {
          status: "failed",
          message: "Failed to log in, Please Check Your Credentials",
        };
      }
    } catch (err) {
      console.log(err);
      return {
        status: "failed",
        message:
          err?.response?.data?.error?.message ||
          err?.response?.data?.error ||
          err?.response?.data?.message ||
          err.toString(),
      };
    }
  };

  const handleSignOut = () => {
    localStorage.setItem("entryPath", location.pathname);
    window.location.reload();
    dispatch(setPermissions());
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signOut,
  };
}

export default useAuth;
